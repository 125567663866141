// コメント位置はPrettier対策
class AppConfig {
  //FIXME 本番設定に書き換えてください。
  /**
   * アプリケーションの定数値です。
   */
  APP_CONFIG = {
    //FIXME MockModeの有効無効
    // HttpClientのgetを対象として、dummy.jsonを読み込むフラグです。
    MOCK_MODE: false,
    // 会社コード
    COMPANY_CODE: "1000000",
    // 業務コード
    BIZ_CODE: "HM10000000",
    // APIのURLホスト部
    BASE_URL: "https://cargos.nikkon.jp/",
    // BASE_URL: "https://dev-cargos.nikkon.jp/",
    // BASE_URL: "http://localhost:8080/",
    // 警告灯のアドレス(http://から記載してください)
    // FIXME 警告灯のURL仕様に合わせて変更してください
    // CALLWARNINGLIGHT_URL: "http://dts-test.com/",
    CALLWARNINGLIGHT_URL: "http://192.168.1.1/",
  };
  /**
   * Cognitoの設定です。
   */
  //FIXME 本番設定に書き換えてください。
  COGNITO = {
    USER_POOL_ID: "ap-northeast-1_oI1bpNpuc",
    APP_CLIENT_ID: "18ghtia5o2or0cftgqlfl5v9st",
    IDENTITY_POOL_ID: "ap-northeast-1:83e68e4d-fd20-40f8-a485-1cfb22564d4b",
    AUTHFLOW: "auth_flow：ADMIN_USER_PASSWORD_AUTH",
    REGION: "ap-northeast-1",
  };

  /**
   * APIのURLです。
   */
  API_URL = {
    /**
     * 拠点マスタ
     */
    bases: "master/bases",
    /**
     * 搬入先マスタ参照
     */
    destinations: "master/destinations",
    /**
     * 取引先マスタ
     */
    suppliers: "master/suppliers",
    /**
     * 進捗状況サマリ参照
     */
    progressSummary: "business/progress-summary",
    /**
     * 進捗状況詳細参照
     */
    progressDetail: "business/progress-detail",
    /**
     * 搬入指示データ更新
     */
    deliveryrequests: "business/deliveryrequests",
    /**
     * 実績データファイル作成
     */
    shipments: "business/shipments-file",
    /**
     * 搬入先グループヘッダ
     */
    destinationgrouplist: "master/destinationgroup-list",
    /**
     * 搬入先グループ詳細
     */
    destinationgroup: "master/destinationgroups-details",
    /**
     * 計画コード参照
     */
    getPlanCodeList: "master/destinationgroups-plancode",
    /**
     * 搬入先アドレス
     */
    getDeliveryDestAddressList: "master/destinationgroups-deliverydestaddress",
    /**
     * 搬入先グループマスタ重複チェック
     */
    destinationgroupsCheck: "master/destinationgroups-check",
    /**
     * 搬入先グループマスタ登録更新
     */
    deliverydestgrp: "business/deliverydestgrp",
  };

  /**
   * CognitoグループIDです。
   */
  AUTH_GROUP_ID = {
    /**
     * 日梱担当者A
     */
    COM_N_001: "AUTH-COM-N-001",
    /**
     *日梱担当者B
     */
    COM_N_002: "AUTH-COM-N-002",
    /**
     *他社担当者
     */
    COM_O_001: "AUTH-COM-O-001",
    /**
     *他社参照者
     */
    COM_O_101: "AUTH-COM-O-101",
    /**
     *日梱積込作業者A
     */
    LOD_N_001: "AUTH-LOD-N-001",
    /**
     *日梱積込作業者B
     */
    LOD_N_002: "AUTH-LOD-N-002",
    /**
     * 他社積込作業者
     */
    LOD_O_001: "AUTH-LOD-O-001",
  };

  /**
   * 画面IDです。
   */
  SCREEN_ID = {
    /**
     * ログイン・共通
     */
    P_COM_001: "P-COM-001",
    /**
     * 進捗状況サマリー
     */
    P_MNG_001: "P-MNG-001",
    /**
     * 進捗状況詳細
     */
    P_MNG_002: "P-MNG-002",
    /**
     * 出荷実績抽出
     */
    P_MNG_003: "P-MNG-003",
  };

  /**
   * 機能IDです。
   * 画面ID_機能名というルールとしています。
   */
  FUNCTION_ID = {
    /**
     * 進捗状況詳細(参照)
     */
    P_MNG_002_READ: "P-MNG-002_READ",
    /**
     * 進捗状況詳細(更新)
     */
    P_MNG_002_UPDATE: "P-MNG-002_UPDATE",
  };

  /**
   * 特定の画面に依存した定数値です。
   */
  ScreenConst = {
    /**
     * 警告灯の連続Error回数の制限値です。
     */
    WarningLightErrCntBorder: 5,
  };

  /**
   * 進捗状況サマリの処理区分です。
   */
  SUMMARY_OPERATION_TYPE = {
    /**
     * 積付
     */
    pack: "1",
    /**
     * 積込
     */
    load: "2",
  };
  /**
   * 進捗状況詳細の処理区分です。
   */
  DETAIL_OPERATION_TYPE = {
    /**
     * 全件
     */
    all: "0",
    /**
     * 未実施
     */
    non: "1",
    /**
     * 積付済
     */
    packComplete: "2",
    /**
     * 積込済
     */
    loadComplete: "3",
  };

  /**
   * 進捗状況詳細の積付状況ステータスです。
   */
  DETAIL_PACK_STATUS = {
    /**
     *0:済
     */
    completion: "0",
    /**
     * 1:未済
     */
    incomplete: "1",
    /**
     * 2:未済（警告）
     */
    warning: "2",
    /**
     * 3:未済（遅延）
     */
    delay: "3",
  };

  /**
   * 進捗状況詳細の積込状況ステータスです。
   */
  DETAIL_LOAD_STATUS = {
    /**
     * 0:済
     */
    completion: "0",
    /**
     * 1:未済
     */
    incomplete: "1",
    /**
     * 2:未済（警告）
     */
    warning: "2",
    /**
     * 3:未済（遅延）
     */
    delay: "3",
  };

  DETAIL_PROCESS_TYPE = {
    /**
     * 1：積付数量
     */
    packCompletion: 1,
    /**
     * 2：積込数量
     */
    loadCompletion: 2,
    /**
     * 3：積付期限
     */
    packDeadlineUpdate: 3,
    /**
     * 4：積込期限"
     */
    loadDeadlineUpdate: 4,
  };

  /**
   * 進捗状況サマリの検索区分です。
   */
  SEARCH_TYPE = {
    /**
     * 取引先
     */
    supplier: "1",
    /**
     * 搬入場所
     */
    deliveryAddress: "2",
  };
  /**
   * 警告灯のステータス分類です。
   */
  WARNING_LIGHT_STATUS = {
    nomaru: 0,
    difference: 1,
    warning: 2,
    delay: 3,
  };
  /**
   * 搬入先マスタの検索区分です。
   */
  DESTINATIONS_SEARCH_TYPE = {
    /**
     * 通常
     */
    normal: "00",
    /**
     * 進捗状況サマリ
     */
    progressSummary: "01",
  };
  /**
   * 拠点マスタ参照の拠点区分です。
   */
  BASE_TYPE = {
    /**
     * 納品先（製作所）
     */
    factory: "2",
    /**
     * システム運用会社（日梱拠点）
     */
    systemOperationCompany: "4",
  };

  /**
   * 取引先マスタ参照の取引先区分です。
   */
  SUPPLIER_TYPE = {
    /**
     * 1：荷主（メーカー）
     */
    maker: "1",
  };

  /**
   * 更新トークン
   */
  UPDATE_TOKEN = {
    /**
     * 分
     */
    tokenTimeOut: 1439,
  };

  /**
   * ダイアログ
   */
  DIALOG = {
    /**
     * タイトル
     */
    title: "結果",
  };
}

export const appConfig = new AppConfig();
