// eslint-disable-next-line no-unused-vars
import axios, { AxiosRequestConfig } from "axios";
import { Cognito } from "./Cognito";
import { appConfig } from "./AppConfig";
import { dateTimeHelper } from "./DateTimeHelper";
import { mockData } from "./MockData";
import SigV4ClientFactory from "./lib/sigV4Client";
import uritemplate from "url-template";
import { v4 as uuidv4 } from "uuid";

/**
 * axiosとCognitoアクセスをラップした専用クラスです。
 */
export default class HttpClient {
  /**
   * コンストラクタ
   * @param {String} baseURL
   */
  constructor() {
    axios.defaults.timeout = 30000;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.baseURL = appConfig.APP_CONFIG.BASE_URL;
    this.cognito = new Cognito();
  }

  // MEMO 外部ライブラリで頻繁に使用され、
  // 不要な解決がされる可能性が高いため、configという変数は避けてください。

  /**
   * HTTPメソッド定数定義
   */
  Method = {
    /**
     * GET
     */
    GET: "GET",
    /**
     * POST
     */
    POST: "POST",
  };

  /**
   * BaseURLを設定します。
   * @param {String} defaultsBaseURL
   */
  setDefaultsBaseURL(defaultsBaseURL) {
    axios.defaults.baseURL = defaultsBaseURL;
  }

  /**
   * 現時点で有効なヘッダーを設定します。
   * @param {AxiosRequestConfig} _config
   */
  setHeader(_config) {
    return new Promise((resolve, reject) => {
      if (_config === undefined) {
        _config = {};
      }
      // APIアクセスであれば常にトークンを更新して設定します。
      if (
        _config.baseURL == undefined ||
        _config.baseURL == appConfig.APP_CONFIG.BASE_URL ||
        (axios.defaults.baseURL == appConfig.APP_CONFIG.BASE_URL && _config.baseURL == undefined)
      ) {
        this.cognito
          .getIdToken()
          .then((idToken) => {
            _config.headers = { Authorization: idToken };
            resolve(_config);
          })
          .catch((error) => {
            console.error("setHeader error", error);
            reject(_config);
          });
      } else {
        resolve(_config);
      }
    });
  }

  /**
   * axios getのラッパーメソッドです。
   * @param {String} uri
   * @param {AxiosRequestConfig} _config
   * @returns {Promise<AxiosResponse<any>>}
   */
  async get(uri, _config) {
    const newConfig = await this.setHeader(_config);
    return axios.get(uri, newConfig);
  }

  /**
   * axios postのラッパーメソッドです。
   * @param {String} uri
   * @param {Json} data
   * @param {AxiosRequestConfig} _config
   * @returns {Promise<AxiosResponse<any>>}
   */
  async post(uri, data, _config) {
    const newConfig = await this.setHeader(_config);
    return axios.post(uri, data, newConfig);
  }

  /**
   * axios putのラッパーメソッドです。
   * @param {String} uri
   * @param {Json} data
   * @param {AxiosRequestConfig} _config
   * @returns {Promise<AxiosResponse<any>>}
   */
  async put(uri, data, _config) {
    const newConfig = await this.setHeader(_config);
    return axios.put(uri, data, newConfig);
  }

  /**
   * username, passwordでログインします。
   * @param {String} userId
   * @param {String} password
   * @returns {Promise<any>}
   */
  login(userId, password) {
    return this.cognito.login(userId, password);
  }

  /**
   * ログアウトします。
   * @param {String} userId
   */
  logout(userId) {
    this.cognito.logout(userId);
  }

  /**
   * トークンをクリアします。
   */
  clearToken() {
    this.cognito.clearToken();
  }

  /**
   * credentialsを取得します。
   * ※ApiGatewayを並列実行したい場合にcredentialsを更新するために呼びます。
   * @returns credentials
   *
  getApiGatewayCredentials() {
    return this.cognito.getIdToken().then((idToken) => {
      return this.cognito.getApiGatewayCredentials(idToken);
    });
  }
*/
  getApiGatewayCredentials() {
    return this.cognito.getIdToken().then((idToken) => {
      return this.cognito.makeCredentials(idToken);
    });
  }
  /**
   * ユーザーの属性を取得します。
   * @param {String} userId
   * @returns {Promise<any>}
   */
  getUserAttribute(userId) {
    return this.cognito.getUserAttribute(userId);
  }

  /**
   * GetAPIリクエスト用のConfigを作成します。
   * @returns {AxiosRequestConfig}
   */
  createGetApiRequestConfig() {
    const _config = {};
    _config.params = {
      reqComCompanyCode: appConfig.APP_CONFIG.COMPANY_CODE,
      reqComBizCode: appConfig.APP_CONFIG.BIZ_CODE,
      reqComDeviceImei: "",
      reqComAppVersion: "",
      reqComExecUser: sessionStorage.getItem("username"),
      reqComExecTimestamp: dateTimeHelper.toStringNowDate(),
      reqComRequestId: uuidv4(),
      reqComPaginationFlag: false,
      reqComPageIndex: 0,
      reqComPageLimit: 0,
    };

    return _config;
  }

  /**
   * GetAPIリクエスト用のConfigを作成します。
   * @returns
   */
  createRequestBodyConfig() {
    const body = {};
    let kvp = {};
    kvp.reqComCompanyCode = appConfig.APP_CONFIG.COMPANY_CODE;
    kvp.reqComBizCode = appConfig.APP_CONFIG.BIZ_CODE;
    kvp.reqComDeviceImei = "";
    kvp.reqComAppVersion = "";
    kvp.reqComExecUser = sessionStorage.getItem("username");
    kvp.reqComExecTimestamp = dateTimeHelper.toStringNowDate();
    kvp.reqComRequestId = uuidv4();
    kvp.reqComPaginationFlag = false;
    kvp.reqComPageIndex = 0;
    kvp.reqComPageLimit = 0;

    body.reqCom = kvp;
    body.reqIdv = {};
    return body;
  }

  /**
   * API GatewayのIAM認証用Getリクエストです。
   * @param {String} uri
   * @param {any} data
   * @param {AxiosRequestConfig} _config
   * @returns {Promise<R>}
   */
  secureGet(url, _config) {
    return this.apiGatewayRequest(this.Method.GET, url, {}, _config);
  }

  /**
   * API GatewayのIAM認証用Postリクエストです。
   * @param {String} uri
   * @param {any} data
   * @param {AxiosRequestConfig} _config
   * @returns {Promise<R>}
   */
  securePost(uri, data, _config) {
    return this.apiGatewayRequest(this.Method.POST, uri, data, _config);
  }

  /**
   * ApiGatewayへの署名付きAPIアクセスを行う実体です。
   * @param {String} method
   * @param {String} url
   * @param {any} data
   * @param {AxiosRequestConfig} _config
   * @returns {Promise<R>}
   */
  apiGatewayRequest(method, url, data, _config) {
    if (_config.baseURL == undefined && appConfig.APP_CONFIG.MOCK_MODE) {
      // MOCKを使用する場合の処理です。
      return new Promise((resolve, reject) => {
        const data = mockData.getData(url);
        if (data) {
          const response = { data: data };
          resolve(response);
        } else {
          console.error("apiGatewayRequest error", data);
          reject(data);
        }
      });
    }

    if (_config.baseURL === undefined || _config.baseURL == "") {
      _config.baseURL = appConfig.APP_CONFIG.BASE_URL;
    }
    // トークンを再取得します。
    return this.cognito
      .getIdToken()
      .then(() => {
        // baseUrlの末尾スラッシュを補正します。
        let baseUrl = this.removeUrlEndSlash(_config.baseURL);

        // baseUrlの先頭スラッシュがあれば削除します。
        let _url;
        if (url[url.length - 1] == "/") {
          _url = url;
        } else {
          _url = "/" + url;
        }

        // 署名付きリクエスト発行クライアントを初期化します。
        const clientConfig = {
          endpoint: baseUrl,
          region: appConfig.COGNITO.REGION,
          serviceName: "execute-api",
          accessKey: sessionStorage.getItem("credentials.accessKeyId"),
          secretKey: sessionStorage.getItem("credentials.secretAccessKey"),
          sessionToken: sessionStorage.getItem("credentials.sessionToken"),
          defaultContentType: "application/json",
          defaultAcceptType: "application/json",
        };
        // console.info(clientConfig);
        const sigV4ClientFactory = SigV4ClientFactory.newClient(clientConfig);
        // 署名付きリクエストに変換してリクエストします。
        const request = {
          verb: method.toUpperCase(),
          path: uritemplate.parse(_url).expand(_config.params),
          headers: _config.headers || {},
          queryParams: _config.params,
          body: data,
        };
        const sigV4Request = sigV4ClientFactory.makeRequest(request);
        return axios(sigV4Request);
      })
      .catch((err) => {
        console.error("apiGatewayRequest getIdToken error", err);
        return err;
      });
  }
  removeUrlEndSlash(url) {
    return url.replace(/\/$/, "");
  }
}

export const httpClient = new HttpClient();
