import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  // eslint-disable-next-line no-unused-vars
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import { appConfig } from "./AppConfig";
import { config as awsConfig, CognitoIdentityCredentials } from "aws-sdk";

export class Cognito {
  /**
   * 設定を行います。
   * @param {String} username
   */
  configure(username) {
    this.cognitoUserPool = new CognitoUserPool({
      UserPoolId: appConfig.COGNITO.USER_POOL_ID,
      ClientId: appConfig.COGNITO.APP_CLIENT_ID,
      Storage: sessionStorage,
    });
    this.userData = { Username: username, Pool: this.cognitoUserPool, Storage: sessionStorage };
  }

  /**
   * username, passwordでログインします。
   * @param {String} username
   * @param {String} password
   * @returns {Promise<any>}
   */
  login(username, password) {
    console.debug("login");
    this.configure(username);
    sessionStorage.setItem("username", username);
    const cognitoUser = new CognitoUser(this.userData);
    const authenticationData = { Username: username, Password: password };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          this.saveToken(result);
          sessionStorage.setItem("updateToken", new Date().getTime());
          resolve(result);
        },
        onFailure: (err) => {
          console.error("login Fail", err);
          reject(err);
        },
      });
    });
  }

  /**
   * cognitoへ更新トークンを使用して再取得を試みます。
   * （AWS仕様：残り時間が十分にある場合、同じトークンが返されます）
   * @param {String} username
   * @returns
   */
  updateRefreshToken(username) {
    console.debug("updateRefreshToken");
    this.configure(username);
    const cognitoUser = new CognitoUser(this.userData);
    return new Promise((resolve, reject) => {
      cognitoUser.getSession((error, session) => {
        if (session.isValid()) {
          this.saveToken(session);
          resolve(session);
        } else {
          console.error("updateRefreshToken error", error);
          reject(error);
        }
      });
    });
  }

  /**
   * ログアウトします。
   * @param {String} username
   */
  logout(username) {
    console.debug("logout");
    this.configure(username);
    if (this.cognitoUserPool.getCurrentUser() != null) {
      if (awsConfig.credential && awsConfig.credentials.clearCachedId) {
        awsConfig.credentials.clearCachedId();
      }
    }
    this.cognitoUserPool.getCurrentUser().signOut();
    this.clearToken();
  }

  /**
   * アクセストークン、またはIDトークンの残り時間(秒)を返します。
   * 今後の仕様変更時の調査工数低減のため、残しておく。
   * @param {String} token
   * @returns {Integer}
   */
  getTokenRemainingTime(token) {
    console.debug("getTokenRemainingTime");
    const payload = token.split(".")[1];
    const decodePayload = JSON.parse(
      window.atob(payload.replaceAll("-", "+").replaceAll("_", "/"))
    );
    // 現在の時刻を秒で取得する。
    const nowSec = Math.floor(new Date().getTime() / 1000);
    return decodePayload.exp - nowSec;
  }

  /**
   * 有効期間に合わせて再取得したIDトークンを返します。
   * @returns {Promise<any>}
   */
  getIdToken() {
    // MEMO(仕様) 残り時間に関わらずトークン再取得を呼び出すためコメントアウト
    // 今後の仕様変更時の調査工数低減のため、残しておく。
    // const idToken = sessionStorage.getItem("idToken");
    // var tokenRemainingTime = this.getTokenRemainingTime(idToken);
    // console.debug("トークン残り時間:" + tokenRemainingTime + "秒");
    // if (tokenRemainingTime >= 120) {
    //   return Promise.resolve(idToken);
    // } else {
    const username = sessionStorage.getItem("username");
    return new Promise((resolve, reject) => {
      this.updateRefreshToken(username)
        .then((result) => {
          if (result.isValid()) {
            resolve(result.idToken.jwtToken);
          } else {
            reject(result);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
    // }
  }

  /**
   * API利用に必要なクレデンシャルをIDプールから取得します。
   * @param {String} idToken
   * @returns {any}
   *
  getApiGatewayCredentials(idToken) {
    this.getTokenRemainingTime(idToken);
    return Promise.resolve(true);
    /*
    // GlobalConfigInstanceをスレッドセーフにすることが実装上難しいため、タイミングロックする。
    var tokenRemainingTime = this.getTokenRemainingTime(idToken);
    console.debug("getApiGatewayCredentials tokenRemainingTime ", tokenRemainingTime);
    if (awsConfig.credentials && awsConfig.credentials.accessKeyId && tokenRemainingTime >= 700) {
      return Promise.resolve(awsConfig.credentials);
    } else {
      return new Promise((resolve, reject) => {
        console.debug("クレデンシャル取得");
        awsConfig.region = appConfig.COGNITO.REGION;

        // ログイン状態でCredentialsを生成
        awsConfig.credentials = new CognitoIdentityCredentials({
          IdentityPoolId: appConfig.COGNITO.IDENTITY_POOL_ID,
          Logins: {
            [`cognito-idp.${appConfig.COGNITO.REGION}.amazonaws.com/${appConfig.COGNITO.USER_POOL_ID}`]:
              idToken,
          },
        });

        const _awsConfig = awsConfig;

        return _awsConfig.credentials
          .getPromise()
          .then(() => {
            resolve(_awsConfig.credentials);
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
    *
}
  */
  makeCredentials(idToken) {
    console.log("makeCredentials");
    return new Promise((resolve, reject) => {
      awsConfig.region = appConfig.COGNITO.REGION;

      // ログイン状態でCredentialsを生成
      awsConfig.credentials = new CognitoIdentityCredentials({
        IdentityPoolId: appConfig.COGNITO.IDENTITY_POOL_ID,
        Logins: {
          [`cognito-idp.${appConfig.COGNITO.REGION}.amazonaws.com/${appConfig.COGNITO.USER_POOL_ID}`]:
            idToken,
        },
      });
      awsConfig.credentials.clearCachedId();
      const _awsConfig = awsConfig;

      return _awsConfig.credentials
        .getPromise()
        .then(() => {
          sessionStorage.setItem("credentials.accessKeyId", _awsConfig.credentials.accessKeyId);
          sessionStorage.setItem(
            "credentials.secretAccessKey",
            _awsConfig.credentials.secretAccessKey
          );
          sessionStorage.setItem("credentials.sessionToken", _awsConfig.credentials.sessionToken);
          resolve(_awsConfig.credentials);
        })
        .catch((err) => {
          console.log("makeCredentials error", err);
          reject(err);
        });
    });
  }
  /**
   * CognitoUserSessionからロジック上必要な情報を保存します。
   * ※必要なトークン情報はCognitoライブラリの管理下ですのため意識しません。
   * @param {CognitoUserSession} session
   */
  saveToken(session) {
    // アクセストークン(cognitoライブラリで管理されるためコメントアウト)
    //sessionStorage.setItem("accessToken", session.accessToken.jwtToken);
    // アクセストークの有効期限
    sessionStorage.setItem("accessToken.payload.exp", session.accessToken.payload.exp);
    sessionStorage.setItem("accessToken.payload.iat", session.accessToken.payload.iat);
    // IDトークン(ライブラリ管理はされるが取り出す機会が多いため別名で保持しておく)
    sessionStorage.setItem("idToken", session.idToken.jwtToken);
    // IDトークンの有効期限
    sessionStorage.setItem("idToken.payload.exp", session.idToken.payload.exp);
    // 更新トークン
    sessionStorage.setItem("refreshToken", session.refreshToken.token);
    // ユーザー名 ユーザー名を上書きする必要はないためコメントアウト
    //sessionStorage.setItem("username", session.accessToken.payload.username);
    // グループ(役割) 【仕様】rolesではなくgroupsを格納します。
    let role = session.idToken.payload["cognito:groups"];
    if (role === undefined) {
      role = [];
    }
    sessionStorage.setItem("role", role);
    // 姓
    sessionStorage.setItem("family_name", session.idToken.payload["family_name"]);
    // 名
    sessionStorage.setItem("given_name", session.idToken.payload["given_name"]);
    // 営業所
    sessionStorage.setItem(
      "sales_office_code",
      session.idToken.payload["custom:sales_office_code"]
    );
    // ログイン時間
    sessionStorage.setItem("accessToken", new Date().getTime());
  }

  /**
   * セッションストレージをクリアします。
   * ※Cognito管理下のトークン情報の削除にはログアウトが適切です。
   */
  clearToken() {
    // アクセストークの有効期限
    sessionStorage.removeItem("accessToken.payload.exp");
    sessionStorage.removeItem("accessToken.payload.iat");
    // IDトークン
    sessionStorage.removeItem("idToken");
    // IDトークンの有効期限
    sessionStorage.removeItem("idToken.payload.exp");
    // 更新トークン
    sessionStorage.removeItem("refreshToken");
    // ユーザー名
    sessionStorage.removeItem("username");
    // グループ(役割)
    sessionStorage.removeItem("role");
    // 姓
    sessionStorage.removeItem("family_name");
    // 名
    sessionStorage.removeItem("given_name");
    // 営業所
    sessionStorage.removeItem("sales_office_code");
    // ログイン時間
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("updateToken");
    //どうしても自前でクリアしたい場合、以下を有効化してください。
    //CognitoIdentityServiceProvider.13qo9uaiej7r8lqoh9dj87fonb.test01.accessToken
    // const userName = sessionStorage.removeItem(
    //   "CognitoIdentityServiceProvider." + appConfig.COGNITO.APP_CLIENT_ID + ".LastAuthUser"
    // );
    // if (userName) {
    //   const nameAry = ["accessToken", "idToken", "refreshToken", "clockDrift"];
    //   for (let name of nameAry) {
    //     const key =
    //       "CognitoIdentityServiceProvider." +
    //       appConfig.COGNITO.APP_CLIENT_ID +
    //       "." +
    //       userName +
    //       "." +
    //       name;
    //     sessionStorage.removeItem(key);
    //   }
    // }
  }

  /**
   * ログインしているかを判定します。
   * @param {String} username
   * @returns {CognitoUserSession}
   */
  isAuthenticated(username) {
    this.configure(username);
    const cognitoUser = this.cognitoUserPool.getCurrentUser();
    if (cognitoUser === null) {
      Promise.reject(cognitoUser);
    }
    return new Promise((resolve, reject) => {
      cognitoUser.getSession((err, session) => {
        if (err) {
          reject(err);
        } else {
          if (!session.isValid()) {
            reject(session);
          } else {
            resolve(session);
          }
        }
      });
    });
  }

  /**
   * 指定したユーザーの属性を取得します。
   * @param {String} username
   * @returns {Map}
   */
  getUserAttribute(username) {
    this.configure(username);
    const cognitoUser = new CognitoUser(this.userData);
    return new Promise((resolve, reject) => {
      cognitoUser.getSession((err, session) => {
        if (err || !session.isValid()) {
          reject(err);
          return;
        }
        cognitoUser.getUserAttributes(function (err, result) {
          if (err) {
            reject(err);
            return;
          }
          // 取得した属性情報を連想配列に格納
          const map = new Map();
          //console.debug(result);
          for (let i = 0; i < result.length; i++) {
            map.set(result[i].getName(), result[i].getValue());
          }
          resolve(map);
        });
      });
    });
  }
}
