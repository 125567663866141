export default class DateTimeHelper {
  /**
   * Constructor
   * @param {String} forrmatStr Format ex:YYYY/MM/DD hh:mm:ss
   */
  constructor(forrmatStr) {
    Object.defineProperty(this, "_defaultFormat", {
      value: "YYYY/MM/DD hh:mm:ss.SSS",
    });
    if (forrmatStr === null || forrmatStr === undefined) {
      this.forrmatStr = this._defaultFormat;
    } else {
      this.forrmatStr = forrmatStr;
    }
  }

  getFormatStr() {
    return this.forrmatStr;
  }

  /**
   * Date形式の日付をフォーマットを指定して文字列を返します。
   * @param {Date} date
   * @returns {String}
   */
  toStringDate(formatString, date) {
    const yearStr = date.getFullYear();
    //getMonthは0～12の戻り値
    //("0" + i).slice(-2);
    const monthStr = ("0" + (1 + date.getMonth())).slice(-2);
    const dayStr = ("0" + date.getDate()).slice(-2);
    const hourStr = ("0" + date.getHours()).slice(-2);
    const minuteStr = ("0" + date.getMinutes()).slice(-2);
    const secondStr = ("0" + date.getSeconds()).slice(-2);
    const milliSecondStr = ("00" + date.getMilliseconds()).slice(-3);

    let replaceStr = formatString.replace(/YYYY/g, yearStr);
    replaceStr = replaceStr.replace(/MM/g, monthStr);
    replaceStr = replaceStr.replace(/DD/g, dayStr);
    replaceStr = replaceStr.replace(/hh/g, hourStr);
    replaceStr = replaceStr.replace(/mm/g, minuteStr);
    replaceStr = replaceStr.replace(/ss/g, secondStr);
    replaceStr = replaceStr.replace(/SSS/g, milliSecondStr);

    return replaceStr;
  }

  /**
   * 現在日付を既定のフォーマットの形式の文字列を返します。
   * @returns {String}
   */
  toStringNowDate() {
    return this.toStringDate(this.getFormatStr(), this.convertJstDateTime(new Date()));
  }

  /**
   * 日本時間の現在日付をyyyyMMddの形式で返します。
   * @returns {String}
   */
  toStringNowYYYYMMDD() {
    return this.toStringDate("YYYYMMDD", this.convertJstDateTime(new Date()));
  }

  /**
   * 日本時間の現在日付をyyyy/MM/ddの形式で返します。
   * @returns {String}
   */
  toStringNowYYYY_MM_DD1() {
    return this.toStringDate("YYYY/MM/DD", this.convertJstDateTime(new Date()));
  }

  /**
   * 日本時間の現在日付をyyyy-MM-ddの形式で返します。
   * @returns {String}
   */
  toStringNowYYYY_MM_DD2() {
    return this.toStringDate("YYYY-MM-DD", this.convertJstDateTime(new Date()));
  }

  /**
   * 渡されたUTC日付を日本時間へ変換して返します。
   * @returns {Date}
   */
  convertJstDateTime(utcDateTime) {
    return new Date(utcDateTime + (new Date().getTimezoneOffset() + 9 * 60) * 60 * 1000);
  }
}

export const dateTimeHelper = new DateTimeHelper();
