//import axios from "axios";
import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import { httpClient } from "./assets/scripts/js/HttpClient";

Vue.config.productionTip = false;

//axios.defaults.timeout = 30000;
//axios.defaults.headers.common["Content-Type"] = "application/json;charset=utf-8";
//Vue.prototype.$axios = axios;

Vue.prototype.$httpClient = httpClient;

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
