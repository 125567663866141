export default class MockData {
  /**
   * 指定のJsonを返却します。
   * @param {String} url
   * @returns
   */
  getData(url) {
    if (url) {
      return this.dummy_db[url];
    }
    return null;
  }

  dummy_db = {
    "master/bases": {
      resCom: {
        resComStatus: "SUCCESS",
        resComCode: "000",
        resComMessage: "",
        resComCount: 0,
      },
      resIdv: {
        resBases: [
          {
            companyCode: "a",
            bizCode: "a",
            baseCode: "of-a001",
            supplierCode: "a",
            baseType: "a",
            baseName: "営業所A",
            baseNameKana: "a",
            baseNameAlt: "a",
            baseZipCode: "a",
            baseAddress: "a",
            baseTelNo: "a",
            opeTimeFrom: "a",
            opeTimeTo: "a",
            dataLimitFrom: "a",
            dataLimitTo: "a",
            detailsVisibleFlag: "a",
            field01: "a",
            field02: "a",
            field03: "a",
            field04: "a",
            field05: "a",
            field06: "a",
            field07: "a",
            field08: "a",
            field09: "a",
            field10: "a",
            field11: "a",
            field12: "a",
            field13: "a",
            field14: "a",
            field15: "a",
            field16: "a",
            field17: "a",
            field18: "a",
            field19: "a",
            field20: "a",
            field21: "a",
            field22: "a",
            field23: "a",
            field24: "a",
            deleteFlag: "a",
            deviceId: "a",
            appVersion: "a",
            createUserCode: "a",
            createDatetime: "a",
            updateUserCode: "a",
            updateDatetime: "a",
          },
          {
            companyCode: "a",
            bizCode: "a",
            baseCode: "of-b001",
            supplierCode: "a",
            baseType: "a",
            baseName: "営業所B",
            baseNameKana: "a",
            baseNameAlt: "a",
            baseZipCode: "a",
            baseAddress: "a",
            baseTelNo: "a",
            opeTimeFrom: "a",
            opeTimeTo: "a",
            dataLimitFrom: "a",
            dataLimitTo: "a",
            detailsVisibleFlag: "a",
            field01: "a",
            field02: "a",
            field03: "a",
            field04: "a",
            field05: "a",
            field06: "a",
            field07: "a",
            field08: "a",
            field09: "a",
            field10: "a",
            field11: "a",
            field12: "a",
            field13: "a",
            field14: "a",
            field15: "a",
            field16: "a",
            field17: "a",
            field18: "a",
            field19: "a",
            field20: "a",
            field21: "a",
            field22: "a",
            field23: "a",
            field24: "a",
            deleteFlag: "a",
            deviceId: "a",
            appVersion: "a",
            createUserCode: "a",
            createDatetime: "a",
            updateUserCode: "a",
            updateDatetime: "a",
          },
        ],
      },
    },
    "master/suppliers": {
      resCom: {
        resComStatus: "SUCCESS",
        resComCode: "000",
        resComMessage: "",
        resComCount: 0,
      },
      resIdv: {
        resSuppliers: [
          {
            companyCode: "a",
            bizCode: "a",
            supplierCode: "a",
            supplierType: "a",
            supplierName: "取引先A",
            supplierNameKana: "a",
            supplierNameAlt: "a",
            supplierZipCode: "a",
            supplierAddress: "a",
            supplierTelNo: "a",
            opeTimeFrom: "a",
            opeTimeTo: "a",
            dataLimitFrom: "a",
            dataLimitTo: "a",
            detailsVisibleFlag: "a",
            field01: "a",
            field02: "a",
            field03: "a",
            field04: "a",
            field05: "a",
            field06: "a",
            field07: "a",
            field08: "a",
            field09: "a",
            field10: "a",
            field11: "a",
            field12: "a",
            field13: "a",
            field14: "a",
            field15: "a",
            field16: "a",
            field17: "a",
            field18: "a",
            field19: "a",
            field20: "a",
            field21: "a",
            field22: "a",
            field23: "a",
            field24: "a",
            deleteFlag: "a",
            deviceId: "a",
            appVersion: "a",
            createUserCode: "a",
            createDatetime: "a",
            updateUserCode: "a",
            updateDatetime: "a",
          },
          {
            companyCode: "a",
            bizCode: "a",
            supplierCode: "z",
            supplierType: "a",
            supplierName: "Z製作所",
            supplierNameKana: "a",
            supplierNameAlt: "a",
            supplierZipCode: "a",
            supplierAddress: "a",
            supplierTelNo: "a",
            opeTimeFrom: "a",
            opeTimeTo: "a",
            dataLimitFrom: "a",
            dataLimitTo: "a",
            detailsVisibleFlag: "a",
            field01: "a",
            field02: "a",
            field03: "a",
            field04: "a",
            field05: "a",
            field06: "a",
            field07: "a",
            field08: "a",
            field09: "a",
            field10: "a",
            field11: "a",
            field12: "a",
            field13: "a",
            field14: "a",
            field15: "a",
            field16: "a",
            field17: "a",
            field18: "a",
            field19: "a",
            field20: "a",
            field21: "a",
            field22: "a",
            field23: "a",
            field24: "a",
            deleteFlag: "a",
            deviceId: "a",
            appVersion: "a",
            createUserCode: "a",
            createDatetime: "a",
            updateUserCode: "a",
            updateDatetime: "a",
          },
        ],
      },
    },
    "master/destinations": {
      resCom: {
        resComStatus: "SUCCESS",
        resComCode: "000",
        resComMessage: "URLからダウンロードしてください。",
        resComCount: 0,
      },
      resIdv: {
        resDestinations: [
          {
            companyCode: "a",
            bizCode: "a",
            planCode: "a",
            deliveryDestAddress: "17LF06LF",
            deliveryDestName: "a",
            deleteFlag: "a",
            deviceId: "a",
            appVersion: "a",
            createUserCode: "a",
            createDatetime: "a",
            updateUserCode: "a",
            updateDatetime: "a",
          },
          {
            companyCode: "a",
            bizCode: "a",
            planCode: "a",
            deliveryDestAddress: "17LF07LF",
            deliveryDestName: "a",
            deleteFlag: "a",
            deviceId: "a",
            appVersion: "a",
            createUserCode: "a",
            createDatetime: "a",
            updateUserCode: "a",
            updateDatetime: "a",
          },
          {
            companyCode: "a",
            bizCode: "a",
            planCode: "a",
            deliveryDestAddress: "17LF08LF",
            deliveryDestName: "a",
            deleteFlag: "a",
            deviceId: "a",
            appVersion: "a",
            createUserCode: "a",
            createDatetime: "a",
            updateUserCode: "a",
            updateDatetime: "a",
          },
        ],
      },
    },
    "business/progress-summary": {
      resCom: { resComStatus: "SUCCESS", resComCode: "000", resComMessage: "", resComCount: 0 },
      resIdv: {
        resProgressList: [
          {
            companyCode: "a",
            bizCode: "b",
            supplierCode: "z",
            supplierNameAlt: "Z製作所",
            deliveryAddressCode: "17LF06LF",
            deliveryAddressName: "06LF",
            planQty00: 0,
            planQty01: 1,
            planQty02: 2,
            planQty03: 3,
            planQty04: 4,
            planQty05: 5,
            planQty06: 6,
            planQty07: 7,
            planQty08: 8,
            planQty09: 9,
            planQty10: 10,
            planQty11: 0,
            planQty12: 0,
            planQty13: 0,
            planQty14: 0,
            planQty15: 0,
            planQty16: 0,
            planQty17: 0,
            planQty18: 0,
            planQty19: 0,
            planQty20: 0,
            planQty21: 0,
            planQty22: 0,
            planQty23: 0,
            actualQty00: 1,
            actualQty01: 2,
            actualQty02: 3,
            actualQty03: 4,
            actualQty04: 5,
            actualQty05: 6,
            actualQty06: 7,
            actualQty07: 8,
            actualQty08: 9,
            actualQty09: 10,
            actualQty10: 11,
            actualQty11: 0,
            actualQty12: 0,
            actualQty13: 0,
            actualQty14: 0,
            actualQty15: 0,
            actualQty16: 0,
            actualQty17: 0,
            actualQty18: 0,
            actualQty19: 0,
            actualQty20: 0,
            actualQty21: 0,
            actualQty22: 0,
            actualQty23: 0,
            status00: "0",
            status01: "1",
            status02: "2",
            status03: "3",
            status04: "0",
            status05: "0",
            status06: "0",
            status07: "0",
            status08: "0",
            status09: "0",
            status10: "0",
            status11: "0",
            status12: "0",
            status13: "0",
            status14: "0",
            status15: "0",
            status16: "0",
            status17: "0",
            status18: "0",
            status19: "0",
            status20: "0",
            status21: "0",
            status22: "0",
            status23: "0",
            sumPlan: 20,
            sumActual: 20,
          },
          {
            companyCode: "a",
            bizCode: "b",
            supplierCode: "z",
            supplierNameAlt: "Z製作所",
            deliveryAddressCode: "17LF07LF",
            deliveryAddressName: "07LF",
            planQty00: 0,
            planQty01: 1,
            planQty02: 2,
            planQty03: 3,
            planQty04: 4,
            planQty05: 5,
            planQty06: 6,
            planQty07: 7,
            planQty08: 8,
            planQty09: 9,
            planQty10: 10,
            planQty11: 0,
            planQty12: 0,
            planQty13: 0,
            planQty14: 0,
            planQty15: 0,
            planQty16: 0,
            planQty17: 0,
            planQty18: 0,
            planQty19: 0,
            planQty20: 0,
            planQty21: 0,
            planQty22: 0,
            planQty23: 0,
            actualQty00: 1,
            actualQty01: 2,
            actualQty02: 3,
            actualQty03: 4,
            actualQty04: 5,
            actualQty05: 6,
            actualQty06: 7,
            actualQty07: 8,
            actualQty08: 9,
            actualQty09: 10,
            actualQty10: 11,
            actualQty11: 0,
            actualQty12: 0,
            actualQty13: 0,
            actualQty14: 0,
            actualQty15: 0,
            actualQty16: 0,
            actualQty17: 0,
            actualQty18: 0,
            actualQty19: 0,
            actualQty20: 0,
            actualQty21: 0,
            actualQty22: 0,
            actualQty23: 0,
            status00: "0",
            status01: "1",
            status02: "2",
            status03: "3",
            status04: "0",
            status05: "0",
            status06: "0",
            status07: "0",
            status08: "0",
            status09: "0",
            status10: "0",
            status11: "0",
            status12: "0",
            status13: "0",
            status14: "0",
            status15: "0",
            status16: "0",
            status17: "0",
            status18: "0",
            status19: "0",
            status20: "0",
            status21: "0",
            status22: "0",
            status23: "0",
            sumPlan: 20,
            sumActual: 20,
          },
          {
            companyCode: "a",
            bizCode: "b",
            supplierCode: "z",
            supplierNameAlt: "Z製作所",
            deliveryAddressCode: "17LF08LF",
            deliveryAddressName: "08LF",
            planQty00: 0,
            planQty01: 1,
            planQty02: 2,
            planQty03: 3,
            planQty04: 4,
            planQty05: 5,
            planQty06: 6,
            planQty07: 7,
            planQty08: 8,
            planQty09: 9,
            planQty10: 10,
            planQty11: 0,
            planQty12: 0,
            planQty13: 0,
            planQty14: 0,
            planQty15: 0,
            planQty16: 0,
            planQty17: 0,
            planQty18: 0,
            planQty19: 0,
            planQty20: 0,
            planQty21: 0,
            planQty22: 0,
            planQty23: 0,
            actualQty00: 1,
            actualQty01: 2,
            actualQty02: 3,
            actualQty03: 4,
            actualQty04: 5,
            actualQty05: 6,
            actualQty06: 7,
            actualQty07: 8,
            actualQty08: 9,
            actualQty09: 10,
            actualQty10: 11,
            actualQty11: 0,
            actualQty12: 0,
            actualQty13: 0,
            actualQty14: 0,
            actualQty15: 0,
            actualQty16: 0,
            actualQty17: 0,
            actualQty18: 0,
            actualQty19: 0,
            actualQty20: 0,
            actualQty21: 0,
            actualQty22: 0,
            actualQty23: 0,
            status00: "0",
            status01: "1",
            status02: "2",
            status03: "3",
            status04: "0",
            status05: "0",
            status06: "0",
            status07: "0",
            status08: "0",
            status09: "0",
            status10: "0",
            status11: "0",
            status12: "0",
            status13: "0",
            status14: "0",
            status15: "0",
            status16: "0",
            status17: "0",
            status18: "0",
            status19: "0",
            status20: "0",
            status21: "0",
            status22: "0",
            status23: "0",
            sumPlan: 20,
            sumActual: 20,
          },
        ],
      },
    },
    "business/progress-detail": {
      resCom: { resComStatus: "SUCCESS", resComCode: "000", resComMessage: "", resComCount: 0 },
      resIdv: {
        resProgressDetailsList: [
          {
            deliveryAddressCode: "17LF08LF",
            supplierNameAlt: "Z製作所",
            planCode: "K2AF04",
            partsNo: "38950-MKT-D011-M11",
            deliverySlipNo: "1234567890123",
            deliverySlipSeq: "seq123",
            partsQty: "53",
            planContainerQty: "20",
            packPlanContainerQty: "20",
            packActualContainerQty: "20",
            loadActualContainerQty: "20",
            packStatus: "0",
            loadStatus: "0",
            packLimitDatetime: "2021/08/11 12:00",
            loadLimitDatetime: "2021/08/11 12:00",
            packUserName: "日梱太郎、日梱次郎",
            loadUserName: "日梱三郎",
          },
          {
            deliveryAddressCode: "17LF08LF",
            supplierNameAlt: "Z製作所",
            planCode: "K2AF04",
            partsNo: "38950-MKT-D011-M11",
            deliverySlipNo: "1234567890123",
            deliverySlipSeq: "seq123",
            partsQty: "53",
            planContainerQty: "20",
            packPlanContainerQty: "20",
            packActualContainerQty: "20",
            loadActualContainerQty: "20",
            packStatus: "0",
            loadStatus: "1",
            packLimitDatetime: "2021/08/11 12:00",
            loadLimitDatetime: "2021/08/11 12:00",
            packUserName: "日梱太郎、日梱次郎",
            loadUserName: "日梱三郎",
          },
          {
            deliveryAddressCode: "17LF08LF",
            supplierNameAlt: "Z製作所",
            planCode: "K2AF04",
            partsNo: "38950-MKT-D011-M11",
            deliverySlipNo: "1234567890123",
            deliverySlipSeq: "seq123",
            partsQty: "53",
            planContainerQty: "20",
            packPlanContainerQty: "20",
            packActualContainerQty: "20",
            loadActualContainerQty: "20",
            packStatus: "0",
            loadStatus: "2",
            packLimitDatetime: "2021/08/11 12:00",
            loadLimitDatetime: "2021/08/11 12:00",
            packUserName: "日梱太郎、日梱次郎",
            loadUserName: "日梱三郎",
          },
          {
            deliveryAddressCode: "17LF08LF",
            supplierNameAlt: "Z製作所",
            planCode: "K2AF04",
            partsNo: "38950-MKT-D011-M11",
            deliverySlipNo: "1234567890123",
            deliverySlipSeq: "seq123",
            partsQty: "53",
            planContainerQty: "20",
            packPlanContainerQty: "20",
            packActualContainerQty: "20",
            loadActualContainerQty: "20",
            packStatus: "0",
            loadStatus: "3",
            packLimitDatetime: "2021/08/11 12:00",
            loadLimitDatetime: "2021/08/11 12:00",
            packUserName: "日梱太郎、日梱次郎",
            loadUserName: "日梱三郎",
          },
          {
            deliveryAddressCode: "17LF08LF",
            supplierNameAlt: "Z製作所",
            planCode: "K2AF04",
            partsNo: "38950-MKT-D011-M11",
            deliverySlipNo: "1234567890123",
            deliverySlipSeq: "seq123",
            partsQty: "53",
            planContainerQty: "20",
            packPlanContainerQty: "20",
            packActualContainerQty: "20",
            loadActualContainerQty: "20",
            packStatus: "1",
            loadStatus: "0",
            packLimitDatetime: "2021/08/11 12:00",
            loadLimitDatetime: "2021/08/11 12:00",
            packUserName: "日梱太郎、日梱次郎",
            loadUserName: "日梱三郎",
          },
          {
            deliveryAddressCode: "17LF08LF",
            supplierNameAlt: "Z製作所",
            planCode: "K2AF04",
            partsNo: "38950-MKT-D011-M11",
            deliverySlipNo: "1234567890123",
            deliverySlipSeq: "seq123",
            partsQty: "53",
            planContainerQty: "20",
            packPlanContainerQty: "20",
            packActualContainerQty: "20",
            loadActualContainerQty: "20",
            packStatus: "1",
            loadStatus: "1",
            packLimitDatetime: "2021/08/11 12:00",
            loadLimitDatetime: "2021/08/11 12:00",
            packUserName: "日梱太郎、日梱次郎",
            loadUserName: "日梱三郎",
          },
          {
            deliveryAddressCode: "17LF08LF",
            supplierNameAlt: "Z製作所",
            planCode: "K2AF04",
            partsNo: "38950-MKT-D011-M11",
            deliverySlipNo: "1234567890123",
            deliverySlipSeq: "seq123",
            partsQty: "53",
            planContainerQty: "20",
            packPlanContainerQty: "20",
            packActualContainerQty: "20",
            loadActualContainerQty: "20",
            packStatus: "1",
            loadStatus: "2",
            packLimitDatetime: "2021/08/11 12:00",
            loadLimitDatetime: "2021/08/11 12:00",
            packUserName: "日梱太郎、日梱次郎",
            loadUserName: "日梱三郎",
          },
          {
            deliveryAddressCode: "17LF08LF",
            supplierNameAlt: "Z製作所",
            planCode: "K2AF04",
            partsNo: "38950-MKT-D011-M11",
            deliverySlipNo: "1234567890123",
            deliverySlipSeq: "seq123",
            partsQty: "53",
            planContainerQty: "20",
            packPlanContainerQty: "20",
            packActualContainerQty: "20",
            loadActualContainerQty: "20",
            packStatus: "1",
            loadStatus: "3",
            packLimitDatetime: "2021/08/11 12:00",
            loadLimitDatetime: "2021/08/11 12:00",
            packUserName: "日梱太郎、日梱次郎",
            loadUserName: "日梱三郎",
          },
          {
            deliveryAddressCode: "17LF08LF",
            supplierNameAlt: "Z製作所",
            planCode: "K2AF04",
            partsNo: "38950-MKT-D011-M11",
            deliverySlipNo: "1234567890123",
            deliverySlipSeq: "seq123",
            partsQty: "53",
            planContainerQty: "20",
            packPlanContainerQty: "20",
            packActualContainerQty: "20",
            loadActualContainerQty: "20",
            packStatus: "2",
            loadStatus: "0",
            packLimitDatetime: "2021/08/11 12:00",
            loadLimitDatetime: "2021/08/11 12:00",
            packUserName: "日梱太郎、日梱次郎",
            loadUserName: "日梱三郎",
          },
          {
            deliveryAddressCode: "17LF08LF",
            supplierNameAlt: "Z製作所",
            planCode: "K2AF04",
            partsNo: "38950-MKT-D011-M11",
            deliverySlipNo: "1234567890123",
            deliverySlipSeq: "seq123",
            partsQty: "53",
            planContainerQty: "20",
            packPlanContainerQty: "20",
            packActualContainerQty: "20",
            loadActualContainerQty: "20",
            packStatus: "2",
            loadStatus: "1",
            packLimitDatetime: "2021/08/11 12:00",
            loadLimitDatetime: "2021/08/11 12:00",
            packUserName: "日梱太郎、日梱次郎",
            loadUserName: "日梱三郎",
          },
          {
            deliveryAddressCode: "17LF08LF",
            supplierNameAlt: "Z製作所",
            planCode: "K2AF04",
            partsNo: "38950-MKT-D011-M11",
            deliverySlipNo: "1234567890123",
            deliverySlipSeq: "seq123",
            partsQty: "53",
            planContainerQty: "20",
            packPlanContainerQty: "20",
            packActualContainerQty: "20",
            loadActualContainerQty: "20",
            packStatus: "2",
            loadStatus: "2",
            packLimitDatetime: "2021/08/11 12:00",
            loadLimitDatetime: "2021/08/11 12:00",
            packUserName: "日梱太郎、日梱次郎",
            loadUserName: "日梱三郎",
          },
          {
            deliveryAddressCode: "17LF08LF",
            supplierNameAlt: "Z製作所",
            planCode: "K2AF04",
            partsNo: "38950-MKT-D011-M11",
            deliverySlipNo: "1234567890123",
            deliverySlipSeq: "seq123",
            partsQty: "53",
            planContainerQty: "20",
            packPlanContainerQty: "20",
            packActualContainerQty: "20",
            loadActualContainerQty: "20",
            packStatus: "2",
            loadStatus: "3",
            packLimitDatetime: "2021/08/11 12:00",
            loadLimitDatetime: "2021/08/11 12:00",
            packUserName: "日梱太郎、日梱次郎",
            loadUserName: "日梱三郎",
          },
          {
            deliveryAddressCode: "17LF08LF",
            supplierNameAlt: "Z製作所",
            planCode: "K2AF04",
            partsNo: "38950-MKT-D011-M11",
            deliverySlipNo: "1234567890123",
            deliverySlipSeq: "seq123",
            partsQty: "53",
            planContainerQty: "20",
            packPlanContainerQty: "20",
            packActualContainerQty: "20",
            loadActualContainerQty: "20",
            packStatus: "3",
            loadStatus: "0",
            packLimitDatetime: "2021/08/11 12:00",
            loadLimitDatetime: "2021/08/11 12:00",
            packUserName: "日梱太郎、日梱次郎",
            loadUserName: "日梱三郎",
          },
          {
            deliveryAddressCode: "17LF08LF",
            supplierNameAlt: "Z製作所",
            planCode: "K2AF04",
            partsNo: "38950-MKT-D011-M11",
            deliverySlipNo: "1234567890123",
            deliverySlipSeq: "seq123",
            partsQty: "53",
            planContainerQty: "20",
            packPlanContainerQty: "20",
            packActualContainerQty: "20",
            loadActualContainerQty: "20",
            packStatus: "3",
            loadStatus: "1",
            packLimitDatetime: "2021/08/11 12:00",
            loadLimitDatetime: "2021/08/11 12:00",
            packUserName: "日梱太郎、日梱次郎",
            loadUserName: "日梱三郎",
          },
          {
            deliveryAddressCode: "17LF08LF",
            supplierNameAlt: "Z製作所",
            planCode: "K2AF04",
            partsNo: "38950-MKT-D011-M11",
            deliverySlipNo: "1234567890123",
            deliverySlipSeq: "seq123",
            partsQty: "53",
            planContainerQty: "20",
            packPlanContainerQty: "20",
            packActualContainerQty: "20",
            loadActualContainerQty: "20",
            packStatus: "3",
            loadStatus: "2",
            packLimitDatetime: "2021/08/11 12:00",
            loadLimitDatetime: "2021/08/11 12:00",
            packUserName: "日梱太郎、日梱次郎",
            loadUserName: "日梱三郎",
          },
          {
            deliveryAddressCode: "17LF08LF",
            supplierNameAlt: "Z製作所",
            planCode: "K2AF04",
            partsNo: "38950-MKT-D011-M11",
            deliverySlipNo: "1234567890123",
            deliverySlipSeq: "seq123",
            partsQty: "53",
            planContainerQty: "20",
            packPlanContainerQty: "20",
            packActualContainerQty: "20",
            loadActualContainerQty: "20",
            packStatus: "3",
            loadStatus: "3",
            packLimitDatetime: "2021/08/11 12:00",
            loadLimitDatetime: "2021/08/11 12:00",
            packUserName: "日梱太郎、日梱次郎",
            loadUserName: "日梱三郎",
          },
        ],
      },
    },
    "business/shipments-file": {
      resCom: { resComStatus: "SUCCESS", resComCode: 0, resComMessage: "", resComCount: 0 },
      resIdv: {
        filePath:
          "http://s3.xxxx.yyyy/エンコードした「出荷実績抽出結果」_システム日時（yyyyMMddhhmm）_userId.csv",
      },
    },
  };
}

export const mockData = new MockData();
