import Vue from "vue";
import VueRouter from "vue-router";
import { screenAuth } from "../assets/scripts/js/ScreenAuth";
import { appConfig } from "../assets/scripts/js/AppConfig";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    alias: "/login",
    name: "Login",
    meta: {
      isPublic: true,
      screenId: appConfig.SCREEN_ID.P_COM_001,
    },
    component: () => import(/* webpackChunkName: "login" */ "../views/login.vue"),
  },
  {
    meta: {
      screenId: appConfig.SCREEN_ID.P_MNG_001,
    },
    path: "/progress_summary",
    name: "ProgressSummary",
    component: () =>
      import(/* webpackChunkName: "progress_summary" */ "../views/ProgressSummary.vue"),
  },
  {
    path: "/progress_detail",
    name: "ProgressDetails",
    meta: {
      screenId: appConfig.SCREEN_ID.P_MNG_002,
    },
    component: () =>
      import(/* webpackChunkName: "progress_detail" */ "../views/ProgressDetail.vue"),
  },
  {
    path: "/shipment_record_extraction",
    name: "ShipmentRecordExtraction",
    meta: {
      screenId: appConfig.SCREEN_ID.P_MNG_003,
    },
    component: () =>
      import(
        /* webpackChunkName: "shipment_record_extraction" */ "../views/ShipmentRecordExtraction.vue"
      ),
  },
  {
    path: "/master_manage_list",
    name: "MasterManageList",
    meta: {
      screenId: appConfig.SCREEN_ID.P_MNG_003,
    },
    component: () =>
      import(
        /* webpackChunkName: "shipment_record_extraction" */ "../views/MstDeliveryDestGrpList.vue"
      ),
  },
  {
    path: "/delivery_dest_grp_details",
    name: "DeliveryDestGrpDetails",
    meta: {
      screenId: appConfig.SCREEN_ID.P_MNG_003,
    },
    component: () =>
      import(
        /* webpackChunkName: "shipment_record_extraction" */ "../views/MstDeliveryDestGrpListDetails.vue"
      ),
  },
  {
    path: "*",
    name: "not_found",
    meta: {
      isPublic: true,
      screenId: appConfig.SCREEN_ID.P_COM_001,
    },
    component: () => import("../views/login.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const roles = sessionStorage.getItem("role");
  let canScreen = false;
  if (roles) {
    const role = roles.split(",")[0];
    canScreen = screenAuth.canScreenAuth(role, to.meta.screenId);
  }
  // FIXME テストコード Cognitoが正式版になったら削除してください。
  //canScreen = true;
  // metaのisPublic設定がtrueでないページについては、idトークンと使用権限が必須
  if (
    to.matched.some((page) => page.meta.isPublic) ||
    (sessionStorage.getItem("idToken") !== null && canScreen)
  ) {
    next();
  } else {
    alert("セッションタイムアウトしています。再度ログインしてください。");
    next("/");
  }
});

export default router;
