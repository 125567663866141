import { appConfig } from "./AppConfig";
export class ScreenAuth {
  authMap;
  constructor() {
    this.authMap = new Map();
    const COM_N_001_Map = new Map();
    COM_N_001_Map.set(appConfig.SCREEN_ID.P_COM_001, true);
    COM_N_001_Map.set(appConfig.SCREEN_ID.P_MNG_001, true);
    COM_N_001_Map.set(appConfig.SCREEN_ID.P_MNG_002, true);
    COM_N_001_Map.set(appConfig.SCREEN_ID.P_MNG_003, true);
    this.authMap.set(appConfig.AUTH_GROUP_ID.COM_N_001, COM_N_001_Map);
    const COM_N_002_Map = new Map();
    COM_N_002_Map.set(appConfig.SCREEN_ID.P_COM_001, true);
    COM_N_002_Map.set(appConfig.SCREEN_ID.P_MNG_001, true);
    COM_N_002_Map.set(appConfig.SCREEN_ID.P_MNG_002, true);
    COM_N_002_Map.set(appConfig.SCREEN_ID.P_MNG_003, true);
    this.authMap.set(appConfig.AUTH_GROUP_ID.COM_N_002, COM_N_002_Map);
    const COM_O_001_Map = new Map();
    COM_O_001_Map.set(appConfig.SCREEN_ID.P_COM_001, true);
    COM_O_001_Map.set(appConfig.SCREEN_ID.P_MNG_001, true);
    COM_O_001_Map.set(appConfig.SCREEN_ID.P_MNG_002, true);
    COM_O_001_Map.set(appConfig.SCREEN_ID.P_MNG_003, true);
    this.authMap.set(appConfig.AUTH_GROUP_ID.COM_O_001, COM_O_001_Map);
    const COM_O_101_Map = new Map();
    COM_O_101_Map.set(appConfig.SCREEN_ID.P_COM_001, true);
    COM_O_101_Map.set(appConfig.SCREEN_ID.P_MNG_001, true);
    COM_O_101_Map.set(appConfig.SCREEN_ID.P_MNG_002, true);
    COM_O_101_Map.set(appConfig.SCREEN_ID.P_MNG_003, false);
    this.authMap.set(appConfig.AUTH_GROUP_ID.COM_O_101, COM_O_101_Map);
  }

  /**
   * 指定したGroupの画面権限のMapを返却します。
   * 存在しない場合は空のMapを返します。
   * @param {String} authGroupId appConfig.AUTH_GROUP_ID
   * @returns {Map}
   */
  getAllAuth(authGroupId) {
    const authGroup = this.authMap.get(authGroupId);
    return authGroup ? authGroup : new Map();
  }

  /**
   * 指定したGroup、ScreenIDの画面使用可否を返却します。
   * 存在しない場合もFalseを返します。
   * @param {String} authGroupId appConfig.AUTH_GROUP_ID
   * @param {String} screenId appConfig.SCREEN_ID
   * @returns {Boolean}
   */
  canScreenAuth(authGroupId, screenId) {
    const authGroup = this.getAllAuth(authGroupId);
    const auth = authGroup.get(screenId);
    return auth ? auth : false;
  }

  // 時間チェック
  canAccess() {
    console.log("canAccess");
    var updateToken = sessionStorage.getItem("updateToken");
    var updateNow = new Date(parseInt(updateToken));
    updateNow.setMinutes(updateNow.getMinutes() + appConfig.UPDATE_TOKEN.tokenTimeOut);
    var url = "/";
    // 警告灯フラグが存在する場合、ログイン画面のパラメータに追加
    if (sessionStorage.getItem("pt") == "1") {
      url = url + "?pt=1";
    }
    if (new Date().getTime() > updateNow) {
      location.href = url;
    } else {
      // ログイン後時間
      var accessToken = sessionStorage.getItem("accessToken");

      // 期限取得
      var exp = sessionStorage.getItem("accessToken.payload.exp");
      var iat = sessionStorage.getItem("accessToken.payload.iat");
      var diff = exp - iat;

      // ログイン時間を計算 + 期限処理
      var now = new Date(parseInt(accessToken));
      now.setSeconds(now.getSeconds() + diff);
      if (new Date().getTime() > now) {
        location.href = url;
      } else {
        sessionStorage.setItem("accessToken", new Date().getTime());
      }
    }
  }
}

export const screenAuth = new ScreenAuth();
